// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  // urlAddress: 'https://api.drsdelivers.co.uk/',
  urlAddress: 'https://staging-api.drsdelivers.co.uk/',
   //urlAddress: 'http://35.179.95.215:9890/',
  //urlAddress: 'http://18.133.76.113:9890/',
  
  //urlAddress: 'http://localhost:9890/',
  //urlAddress: 'http://18.171.61.78:9890/',

};

